import { Link } from 'gatsby';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import React, { ElementType, FC } from 'react';
import styled from '@emotion/styled';

import { COLORS, SITE_URL } from '../constants';
import { FONT_GOTHAM_LIGHT } from '../styles/fonts';

const WrapperStyled = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-family: ${FONT_GOTHAM_LIGHT};
`;

const SeparatorStyled = styled.div`
    position: relative;
    margin: 0 8px;
    height: 18px;
    width: 18px;
    box-sizing: border-box;
    background-color: ${COLORS.isabelline};
    border-radius: 50%;

    &:after {
        top: 6px;
        left: 5px;
        position: absolute;
        display: block;
        content: ' ';
        width: 5px;
        height: 5px;
        border-right: 1px #8f8686 solid;
        border-top: 1px #8f8686 solid;
        transform: rotate(45deg);
    }
`;

const CrumbStyled = styled.div`
    display: flex;
    align-items: flex-end;
`;

const StyledLink = styled(Link)`
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    color: ${COLORS.darkGray};
`;

interface IBreadCrumbsProps {
    className?: string;
    items: {
        title: string;
        url?: string;
    }[];
    separator?: ElementType;
}
const Crumb = (props) => <StyledLink {...props} />;

const DefaultSeparator = () => <SeparatorStyled />;

const BreadCrumbs: FC<IBreadCrumbsProps> = ({
    items = [],
    separator: Separator = DefaultSeparator,
    className,
}) => {
    const countOfChildren = items.length;

    return (
        <>
            <BreadcrumbJsonLd
                itemListElements={items.map((x, i) => ({
                    position: i + 1,
                    name: x.title,
                    item: `${SITE_URL}${x.url}`,
                }))}
            />
            <WrapperStyled
                itemScope
                itemType="https://schema.org/BreadcrumbList"
                aria-label="breadcrumb"
                className={className}
            >
                {items.map(({ url, title }, index) => (
                    <CrumbStyled
                        itemProp="itemListElement"
                        itemScope
                        itemType="https://schema.org/ListItem"
                        key={`${url}-${title}`}
                    >
                        <Crumb to={url} itemProp="item">
                            <span
                                itemProp="name"
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        </Crumb>
                        <meta itemProp="position" content={`${index + 1}`} />
                        {countOfChildren - 1 > index && <Separator />}
                    </CrumbStyled>
                ))}
            </WrapperStyled>
        </>
    );
};

export default BreadCrumbs;
