import React, { ComponentType, HTMLProps } from 'react';
import styled from '@emotion/styled';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SectionTitle from '../components/SectionTitle';
import Devider from '../components/Devider';
import { Seo } from '../models';
import { LAYOUT_WIDTH } from '../constants';

import { device } from './commonStyled';
import BreadCrumbs from './Breadcrumbs';

interface PageLayoutProps {
    title?: string;
    seoMeta?: Seo;
    breadcrumbs?: { title: string; url?: string }[];
    contentProps?: HTMLProps<'div'>;
}

const DEFAULT_BREADCRUMB = [{ title: 'Главная', url: '/' }];

const PageLayout: React.FC<PageLayoutProps> = ({
    children,
    title,
    seoMeta,
    contentProps,
    breadcrumbs = [],
}) => {
    return (
        <Layout contentProps={contentProps} seoMeta={seoMeta}>
            <Header>
                <HeaderWrap>
                    {title && (
                        <>
                            <BreadCrumbsStyled
                                items={[...DEFAULT_BREADCRUMB, ...breadcrumbs]}
                            />
                            <DeviderWrap>
                                <Devider />
                            </DeviderWrap>
                            <SectionTitleStyled isMain title={title} />
                        </>
                    )}
                </HeaderWrap>
            </Header>
            <Wrapper>{children}</Wrapper>
            <Footer />
        </Layout>
    );
};

export default PageLayout;

const BreadCrumbsStyled = styled(BreadCrumbs)`
    margin: 0px 0 18px 0;
    align-self: flex-start;
`;

const SectionTitleStyled = styled(SectionTitle)`
    margin-bottom: 36px;
    text-align: center;

    @media ${device.mobileMedium} {
        margin-bottom: 12px;
    }
`;

const DeviderWrap = styled.div`
    margin-bottom: 20px;

    @media ${device.mobileMedium} {
        display: none;
    }
`;

const HeaderWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: ${LAYOUT_WIDTH}px;
    width: 100%;
    padding: 12px;
`;

const Wrapper = styled.div`
    margin-bottom: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
